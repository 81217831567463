import { filterToQuery } from '@/utils/smarteh/tasks/filter'
import { paginationToQuery } from '@/utils/smarteh/pagination'
import { orderingToQuery } from '@/utils/smarteh/ordering'

let fetchCancel,
  fetchStateCancel,
  fetchFilterApplicantsCancel

export const FIELDS = 'client,urgency,stage,prengi_id,serviteh_id,created_at,object,category,description,deadline_at,' +
  'applicant,comments,favorite,workers,activity_code'

const array_fill_keys = (keys, value) => {
  const obj = {}
  for (const key of keys)
    obj[key] = value
  return obj
}

export default {
  fetch ({ commit, state }) {
    if (fetchCancel) fetchCancel()
    commit('SET_ERROR', null)
    commit('SET_LOADING', true)
    const params = {
      ...filterToQuery(state.filter),
      ...paginationToQuery(state),
      ...orderingToQuery(state),
      fields: FIELDS,
    }
    const promise = this.$axios.getWithCancel('tasks', { params })
    fetchCancel = promise.cancel
    promise.then(({ data, meta, timestamp }) => {
      fetchCancel = null
      commit('SET_TASKS', data)
      commit('SET_META', meta)
      commit('SET_TIMESTAMP', timestamp)
      commit('SET_LOADING', false)
    }).catch((e) => {
      if (this.$axios.isCancel(e)) return
      commit('SET_ERROR', e)
      commit('SET_LOADING', false)
    })
    return promise
  },

  fetchState ({ state, commit }) {
    if (fetchStateCancel) fetchStateCancel()
    commit('SET_STATE', {
      loading: true,
      error: null,
    })
    const promise = this.$axios.getWithCancel('tasks/state',
      { params: filterToQuery(state.filter) })
    fetchStateCancel = promise.cancel
    promise.then((data) => {
      commit('SET_STATE', {
        loading: false,
        error: null,
        data,
      })
    }).catch(e => {
      if (this.$axios.isCancel(e)) return
      commit('SET_STATE', {
        loading: false,
        error: e,
        data: null,
      })
    })
    return promise
  },

  fetchFilterClients: fetchFilterFactory('clients', {
    sort: 'companies',
    callback: function (data) {
      data.forEach(c => c.objects.sort(this.$sort.objects))
    },
  }),
  fetchFilterContractors: fetchFilterFactory('contractors',
    { sort: 'companies' }),
  fetchFilterCategories: fetchFilterFactory('categories'),
  fetchFilterStagesResponsibles: fetchFilterFactory('stages_responsibles',
    { sort: 'companies' }),
  fetchFilterStagesSubcontractors: fetchFilterFactory('stages_subcontractors',
    { sort: 'companies' }),
  fetchFilterStagesCreatedBy: fetchFilterFactory('stages_created_by',
    { sort: 'employees' }),
  fetchFilterStagesFinishedBy: fetchFilterFactory('stages_finished_by',
    { sort: 'employees' }),
  fetchFilterStagesParticipantsCompanies: fetchFilterFactory(
    'stages_participants_companies', { sort: 'companies' }),
  fetchFilterStagesParticipantsGroups: fetchFilterFactory(
    'stages_participants_groups', { sort: 'groups' }),
  fetchFilterStagesParticipantsGroupsEmployees: fetchFilterFactory(
    'stages_participants_groups_employees', { sort: 'employees' }),
  fetchFilterStagesParticipantsEmployees: fetchFilterFactory(
    'stages_participants_employees', { sort: 'employees' }),
  fetchFilterStagesParticipantsEmployeesGroups: fetchFilterFactory(
    'stages_participants_employees_groups', { sort: 'groups' }),
  fetchFilterActivityCodes: fetchFilterFactory('activity_codes'),
  fetchFilterReasons: fetchFilterFactory('reasons'),

  async fetchFilterOptions ({ commit, state }, fields) {
    // if (typeof fields === 'string') fields = [fields]
    // if (!Array.isArray(fields)) return;
    // fields = fields.filter(f => !state.filter_loading[f])
    // if (!fields.length) return;
    // try {
    //   commit('SET_FILTER_LOADING', array_fill_keys(fields, true))
    //   commit('SET_FILTER_ERROR', array_fill_keys(fields, null))
    //   const options = await this.$axios.get('tasks/filters', {
    //     params: {
    //       fields: fields.join(',')
    //     }
    //   })
    //   for (const option_key in options) {
    //     switch (option_key) {
    //       case 'clients':
    //         options[option_key].forEach(c => c.objects.sort(this.$sort.objects))
    //       case 'contractors':
    //       case 'subcontractors':
    //         options[option_key].sort(this.$sort.companies)
    //         break
    //       case 'groups':
    //         options[option_key].sort(this.$sort.groups)
    //         break
    //       case 'employees':
    //         options[option_key].sort(this.$sort.employees)
    //         break
    //     }
    //   }
    //   commit('SET_FILTER_OPTIONS', options)
    // } catch (e) {
    //   commit('SET_FILTER_ERROR', array_fill_keys(fields, e))
    // } finally {
    //   commit('SET_FILTER_LOADING', array_fill_keys(fields, false))
    // }
  },

  async fetchFilterApplicants ({ commit, state }, params) {
    if (fetchFilterApplicantsCancel) fetchFilterApplicantsCancel.abort()
    fetchFilterApplicantsCancel = null
    if (typeof params !== 'object') return
    if (Object.keys(params).length < 1) return
    const controller = new AbortController
    try {
      commit('SET_FILTER_LOADING', { applicants: true })
      commit('SET_FILTER_ERROR', { applicants: null })
      const promise = this.$axios.get('tasks/filters/applicants', {
        params,
        signal: controller.signal,
      }) || []
      fetchFilterApplicantsCancel = controller
      const applicants = await promise
      commit('SET_FILTER_OPTIONS', { applicants })
    } catch (e) {
      if (!this.$axios.isCancel(e))
        commit('SET_FILTER_ERROR', { applicants: e })
    } finally {
      fetchFilterApplicantsCancel = null
      commit('SET_FILTER_LOADING', { applicants: false })
    }
  },
}

function fetchFilterFactory (type, { sort, callback } = {}) {
  return async function ({ commit, state }) {
    if (state.filter_loading[type]) return
    try {
      commit('SET_FILTER_LOADING', { [type]: true })
      commit('SET_FILTER_ERROR', { [type]: null })
      let data = await this.$axios.get(`tasks/filters/${type}`)
      if (callback) {
        data = callback.bind(this)(data) || data
      }
      if (typeof sort === 'string') {
        data.sort(this.$sort[sort])
      } else if (typeof sort === 'function') {
        data.sort(sort.bind(this))
      }

      commit('SET_FILTER_OPTIONS', {
        [type]: data,
      })
      return data
    } catch (e) {
      console.error(e)
      commit('SET_FILTER_ERROR', { [type]: e })
    } finally {
      commit('SET_FILTER_LOADING', { [type]: false })
    }
  }
}
